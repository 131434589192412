const moduleImages = [
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-5.svg',
	'modules/tasks/multiple-choice/s1-m3-items-in-work-locker/option-6.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-5.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-6.svg',

	'modules/tasks/spot-errors/s1-m3-person/background.svg',
	'modules/tasks/spot-errors/s1-m1-person/background.svg',
];

export {
	moduleImages
};