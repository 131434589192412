const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'dressing-room',
		modifiers: [
			{
				id: 'lockers',
			},
			{
				id: 'lockers-work'
			},
			{
				id: 'hygiene-lock'
			},
			{
				id: 'lockers-hygiene-lock'
			},
			{
				id: 'no-bench'
			},
			{
				id: 'zoom-in'
			},
			{
				id: 'zoom-in-no-bench'
			},
			{
				id: 'zoom-in-lockers-work'
			}
		]
	},
	{
		id: 'production',
		modifiers: []
	}
];

export {backgroundsData};