const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-welcome',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen første del af hygiejnetræningen. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to first part of the hygiene retraining. This is the first of two modules, you have to complete today.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-points',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Husk, du skal have tre stjerner for at gennemføre den her del af træningen.`,
			en: `You need to get three stars to advance to the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi skabene.`,
			en: `Before entering production, we have to talk about the lockers.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-private-and-work-lockers',
		background: 'dressing-room',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a locker for private clothes and one for work clothes.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-not-in-private-locker',
		layout: 's1-m3-items-not-in-private-locker',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following does NOT belong in the locker for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // everyday shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // towel
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // flip flops
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // wellies
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // hairnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true, // glas bottle with coke
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du må slet ikke have glas med - heller ikke i omklædningen.`,
								en: `Glass is not allowed at all - including in the locker room.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-in-work-locker',
		layout: 's1-m3-items-in-work-locker',
		background: 'dressing-room',
		backgroundModifier: 'lockers-work',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører til i skabet til arbejdstøj?`,
			en: `Which of the following should go in the locker for work clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // hearing protection
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // airpods
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // black work shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,  // glass bottle with coke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // work jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,  // hairnet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej, det skal smides ud efter brug.`,
								en: `No, you have to toss it after use.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-correct-storage',
		background: 'dressing-room',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du at værktøj, engangsbeklædning og andre hjælpemidler ender i vores produkter.`,
			en: `It's important to place things in the right place. That way you can keep tools, clothes, and others to end up in our products.`
		}
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-always-do-before-work',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her inden du går ind i produktionen?`,
			en: `What must you remember to do here before entering production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `At vaske hænder`,
					en: `Wash my hands`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At rette på hue eller hårnet`, 
					en: `Straighten cap or hairnet`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det behøver du kun hvis det sidder dårligt.`,
								en: `Only if they don't fit properly.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `High five my colleagues`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Say the secret password`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m3-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `You must always wash your hands when entering a hygiene lock. Put the actions in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Sprit af i hygiejnesluse`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-good-hand-hygiene',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `God håndhygiejne er vigtig. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `Proper hand hygiene is important. That means you must wash your hands often, but also that you have to do it the right way.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-missing-items',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvad skal du gøre, hvis der mangler sæbe i dispenserne eller skovaskeren, aftørringspapir eller sprit i slusen?`,
			en: `What should you do in case the dispenser or shoe washer is out of soap, or if there are no more paper towels or sanitizer in the lock?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Fylde op og så vaske eller spritte`,
					en: `Refill and then wash or sanitize`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Sige det til min leder`, 
					en: `Say it to my manager`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Råbe om hjælp`,
					en: `Cry for help`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Sige det til den ansvarlige kollega`,
					en: `Say it to the colleague who's in charge of the lock`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-wash-hands-how-often',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often must you wash your hands?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hver anden time`,
					en: `Every second hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Efter toiletbesøg`, 
					en: `After being to the toilet`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Efter pauser`,
					en: `After breaks`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Når jeg passerer en hygiejnesluse`,
					en: `When passing a hygiene lock`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Hver time`,
					en: `Every hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Hvert kvarter`,
					en: `Every 15 minutes`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person2',
		layout: 's1-m1-person',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her. Tryk på dem og tryk så OK når du mener du har dem alle.`,
			en: `See if you can spot the 4 problems here. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hearing-protection'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hair'}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person',
		layout: 's1-m3-person',
		background: 'dressing-room',
		backgroundModifier: 'no-bench',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du tror, du har fundet dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'watch',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hair'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hole'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-production-ready',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Nu er vi klar til at gå ind i produktionen. Det gør vi i næste modul.`,
			en: `Now we're ready to start working. We'll do that in the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Godt gået! Du er nu igennem første del af træningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. Nu fortsætter vi til anden del af træningen.`,
			en: `Well done! You have made it through the first part of the retraining. If you have three stars, you can continue to the next module. If not, you have to retake this module.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};