const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen til hygiejnetræning. Jeg er din leder og hjælper dig gennem spillet.`,
			en: `Welcome to the first part of the hygiene training. I'm your co-worker, and I'll help you through the game.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need to get three stars to advance to the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about being dressed properly.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet sidde? Tryk på de rigtige billeder.`,
			en: `What is the correct way to use hair net? Click on the correct images. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair and ears covered by hair net, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hat covers hair
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // hair sticks out, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // hair and ears covered by hair net, beard but no beardnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // ears stick out, no beard
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kinds of jewelry are NOT allowed in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // Briller med smykkesten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // Næsepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // watch
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // necklace
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // vielsesring, smooth, no stone
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene begins in the changing facilities. See if you can spot the issues on the next image.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
		text: {
			da: `Tryk på de 5 ting som er forkerte på billedet. Tryk OK når du tror, du har dem alle.`,
			en: `Click on the 5 things that are wrong on the image. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wellies'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hairnet'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hearing-protection'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'coke'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-locker-types',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the changing facilities there's a locker for your private clothes and a place for your work clothes. It's important to put things in the right places.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-locker-items',
		layout: 's1-m1-work-locker-items',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'lockers-work',
		text: {
			da: `Hvilke ting skal i skabet til arbejdstøj?`,
			en: `What goes into the locker for work clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // black work shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // wellies
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // hearing protection
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // airpods
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // watch
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		taskId: 's1-m1-image-work-locker-items',
		layout: 's1-m1-work-locker-items',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in-lockers-work',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-ready-production',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in-lockers-work',
		text: {
			da: `Godt, så er vi ved at være klar til at gå ud i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på den.`,
			en: `Alright, I think we're ready to enter the production. On your way out there, you'll pass through a hygiene lock.`
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `When entering a hygiene lock, you must always wash your hands. What is the correct order to do the following actions in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Sprit af i hygiejnesluse`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-person',
		layout: 's1-m1-person',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her. Tryk på dem og tryk så OK når du mener du har dem alle.`,
			en: `See if you can spot the 4 issues with the equipment here. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hearing-protection'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hair'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
				<br /><br />Vælg 1 - og husk at læse alle mulighederne:`,
			en: `After which situations must you remember to wash your hands?
				<br /><br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When going through a hygiene lock`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`, 
					en: `After breaks`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Efter toiletbesøg`,
					en: `After being at the toilet`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the situations above`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to complete this module once more.`
		},
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};