import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyA4pbQc2L0Xr-YXnZeWq90RVkEtAaYXCtQ',
		authDomain: 'cgl-bhj-test.firebaseapp.com',
		projectId: 'cgl-bhj-test',
		storageBucket: 'cgl-bhj-test.appspot.com',
		messagingSenderId: '123211212855',
		appId: '1:123211212855:web:685d4ef78aa1e94f52b7b2'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDbOS_BxsJ4YYwn87JsLAqm4XRZKRHc_zo',
		authDomain: 'cgl-bhj-production.firebaseapp.com',
		projectId: 'cgl-bhj-production',
		storageBucket: 'cgl-bhj-production.appspot.com',
		messagingSenderId: '382895832683',
		appId: '1:382895832683:web:fd849cbc7252d39c4d3b2e'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;