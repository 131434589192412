const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i selve produktionen.`,
			en: `Welcome to the second part of the hygiene retraining. Now we have to start working. Remember, you must get three stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-many-procedures',
		text: {
			da: `Så er vi inde i produktionen. Her er der mange procedurer som skal overholdes.`,
			en: `Now we're inside the production area. Here there are lots of procedures to follow.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-keep-an-eye-on',
		text: {
			da: `Hvad er især vigtigt at holde øje med?`,
			en: `What is especially important to keep an eye on?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Om mine kollegaer arbejder`,
					en: `If my colleagues are working`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hvornår det er tid til frokost`, 
					en: `When it's time for lunch`
				},		
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Om der er fremmedlegemer i produkterne`,
					en: `If foreign bodies enter the product`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At jeg ikke får beskidte fingre`,
					en: `That my fingers don't get dirty`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `At produktionsudstyr, hjælpemidler og andet udstyr i produktionslokalet ikke er i stykker`,
					en: `That machines, tools, and other equipment in the production area aren't broken`
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-before-work',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What must you always do before starting work?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker`,
					en: `Check that my clothes and equipment aren't broken`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Give min daglige leder en high five`, 
					en: `High five my daily manager`
				},		
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Synge BHJ-sangen`,
					en: `Sing the BHJ-song`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det har du lige gjort i hygiejneslusen.`,
								en: `You just did that in the hygiene lock`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-contamination-problem',
		text: {
			da: `Fremmedlegemer er en af de ting, som kan forurene vores produkter. Hvorfor er fremmedlegemer et problem?`,
			en: `Foreign bodies are one of the things that can contaminate our products. Why are foreign bodies a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan gøre skade på kæledyrene`,
					en: `It can hurt the pets`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan koste virksomheden penge`, 
					en: `It can cost the company money`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis vi har bare et enkelt lille fremmedlegeme i vores produkter, skal vi måske tilbagekalde store mængder af vores egne produkter og store mængder af vores kunders produkter  Det er dyrt og koster også på vores og vores kunders omdømme.`,
								en: `Even a single, small foreign body in our products can result in us or our customers having to recall a lot of product. That is expensive and will also hurt our and our customers's reputation.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis kvalitetsafdelingen ser det`,
					en: `Contamination is onlly an issue if Quality sees it`
				},

			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-contamination',
		layout: 's1-m4-contamination',
		background: 'production',
		backgroundModifier: 'no-lamps',
		text: {
			da: `Se om du kan spotte de ting, som kan give problemer med fremmedlegemer. Tryk OK, når du mener, du har dem alle.`,
			en: `See if you can spot the things that can cause problems with foreign bodies. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tub'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'lamp'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-react',
		text: {
			da: `Hvis der er risiko for forurening, så skal du altid reagere på det. Lad os kigge på to forskellige situationer.`,
			en: `If there is a risk of contamination, you must always react on it. Let us take a look at two different situations.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, der er kommet fremmedlegemer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp gets broken in production. There is a risk of foreign bodies in the products. What should you do? Place the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Stop produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tilkald daglig leder eller teamleder`,
					en: `Summon daily manager or team leader`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Afvent frigivelse fra daglig leder eller teamleder`,
					en: `Wait for release by daily manager or team leader`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-glove-tip-missing',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You notice that the tip is missing on one of your plastic gloves. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Lede efter manglende del og smide handske og delen i skraldespand`,
					en: `Search for the missing part and throw the glove in the trash`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`, 
					en: `Continue working. I can change glove in my next break`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change glove so I can continue working`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change my glove`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-good-hygiene-starts-with-you',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-issues',
		layout: 's1-m4-production-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du mener, du har fundet dem alle. `,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'toolbox',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hole'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'floor'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-solve-problems',
		text: {
			da: `Lad os lige kigge på de problemer du så og hvordan man løser dem.`,
			en: `Let's take a closer look at some of the issues you saw and decide how to solve them. `
		}
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		taskId: 's1-m4-match-problems-and-solutions',
		text: {
			da: `Match problemer med rigtig løsning`,
			en: `Match an issue with the right solution.`
		},
		categoriesTitle: {
			da: `Problem`,
			en: `Problem`
		},
		itemsTitle: {
			da: `Løsning`,
			en: `Solution`
		},
		categories: [
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {
				id: 1, 
				text: {
					da: `Værktøjskasse på båndet`,
					en: `Toolbox on the belt`
				}, 
				itemIds: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {
				id: 2, 
				text: {
					da: `Defekt bånd`,
					en: `Faulty belt`
				}, 
				itemIds: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {
				id: 3, 
				text: {
					da: `Manglende finger på handske`,
					en: `Missing finger on glove`
				}, 
				itemIds: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {
				id: 4, 
				text: {
					da: `Stykke fra bånd på gulvet`,
					en: `Piece of belt on the floor`
				}, 
				itemIds: [4]
			}),
		],
		items: [
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Ryd op`,
					en: `Clean up before starting work`
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tjek for skader inden opstart`,
					en: `Check belt before starting work`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis det går i stykker under arbejdet, så stop arbejdet og ring til værktstedet.`,
								en: `Don't start working on faulty equipment. If something breaks while working, stop work and call the workshop.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Stop produktionen og find stykket`,
					en: `Stop production and find missing piece`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Husk at skifte handsker bagefter.`,
								en: `Remember to put the broken glove and the missing piece in the trash.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Smid manglende stykke i skraldespand`,
					en: `Put in the trash`
				}
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-golden-rule',
		text: {
			da: `Hvad er den gyldne regel, hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something, that might be a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Stands produktionen`,
					en: `Stop production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Skriv en note`, 
					en: `Write a note`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Smid alt produkt i nærheden af problemet ud`,
					en: `Trash all product close to the problem`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Spørg en daglig leder eller teamleder`,
					en: `Ask a daily manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge en leder.`,
								en: `Exactly! If you are in doubt, ask a manager.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done',
		text: {
			da: `Du er næsten igennem modulet.`,
			en: `You are almost done with the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-hygiene-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne og opførsel i produktionen?`,
			en: `Why must we be especially aware of hygiene and behaviour in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `It's the law`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne og dårlig opførsel kan ødelægge vores produkter`, 
					en: `Because bad hygiene and behavious can ruin our products`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the above reasons`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om vores fødevaresikkerhedskultur. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about our food safety culture. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and Responsibility`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I am allowed to and have responsibility to react if I spot something that can be a risk to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `React if others do not follow the rules`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement:
				<br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report food safety issues`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my closest manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Reports are acted upon`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finish',
		text: {
			da: `Godt gået! 
				<br />Hvis du har fået tre stjerner, er du nu igennem træningen. Hvis ikke, skal du tage det her modul en gang til.Og husk: Hvis du er i tvivl om noget, så spørg kursuslederen, din daglige leder eller din teamleder.`,
			en: `Well done!
				<br />If you have three stars, you have completed the hygiene training. If not, you have to retake the module. Remember to ask your manager if you are in doubt about anything. 
				<br />Have a nice day out there!`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};